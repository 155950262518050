/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "notiflix/dist/notiflix-3.2.6.min.css";
import "notiflix/dist/notiflix-3.2.6.min.js";
import RoutesPage from "./pages/routesPage/RoutesPage.jsx";
import ReactGA from "react-ga";
ReactGA.initialize("UA-258508156-1");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return <RoutesPage />;
}

export default App;

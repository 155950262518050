/** @format */

import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";
import { AiOutlineLogout } from "react-icons/ai";
// const pages = ['Products', 'Pricing', 'Blog'];
const settings = ["Home", "Account", "Setting"];
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../components/elements/tokenData/components/wallet/connectors";
const Header = ({title}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { deactivate } = useWeb3React();

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [user] = React.useState(localStorage.getItem("@user"));

  async function disconnect() {
    try {
      await deactivate();
    } catch (err) {
      console.log(err);
      Notiflix.Notify.failure(err.message);
    }
  }

  return (
    <AppBar position='sticky' style={{ background: "#09101D" }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Avatar
            alt='AI Square'
            src='assets/tw.png'
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          />
          <Typography
            variant='h5'
            href='/'
            className='mx-3'
            style={{
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              color: "#fff",
              textDecoration: "none",
            }}>
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        
         
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0 }}>
          

            <IconButton onClick={disconnect} sx={{ p: 0 }}>
              <AiOutlineLogout color='#fff' />{" "}
            </IconButton>
          </Box>{" "}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;

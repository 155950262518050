/** @format */

import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import ConnectWallet from "../elements/tokenData/components/wallet/ConnectWallet";
import Notiflix from "notiflix";

function Header() {
  const { active, account } = useWeb3React();
  //   const user = localStorage.getItem("@user");
  // console.log(user);
  const longSting = str => {
    return str.length > 41
      ? `${str.substring(0, 4)}  ... ${str.substring(38, 42)}`
      : str;
  };

  Notiflix.Notify.init({
    width: "300px",
    position: "center-bottom",
    closeButton: false,
    distance: "80px",
  });
  Notiflix.Loading.init({
    backgroundColor: "rgba(0,0,0,0.8)",
    svgColor: "#fff",
    clickToClose: false,
  });

  return (
    <nav className='navbar navbar-expand-lg  fixed-top'>
      <div className='container-fluid'>
        <a className='navbar-brand ' href='/'>
          <img src='assets/logo.svg' alt='' />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon' />
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav m-auto mb-2 mb-lg-0'>
            <li className='nav-item '>
              <a className='nav-link ' href='https://ai2.club' target='_blank'>
                Home
              </a>
            </li>
            <li className='nav-item '>
              <a
                className='nav-link '
                target='_blank'
                rel='noopener noreferrer'
                href='https://squareai.gitbook.io/square-ai'>
                Explore
              </a>
            </li>
            <li className='nav-item '>
              <a className='nav-link active' href='#'>
                Query
              </a>
            </li>

            <li className='nav-item'>
              <a
                className='nav-link'
                target='_blank'
                rel='noopener noreferrer'
                href='https://art.ai2.club'>
                Generate
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                target='_blank'
                rel='noopener noreferrer'
                href='https://earn.ai2.club'>
                Staking
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                target='_blank'
                rel='noopener noreferrer'
                href='https://vote.ai2.club'>
                Governance
              </a>
            </li>
          </ul>
          <div className='navbar-span'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                {active ? (
                  <button className='nav-btn '>
                    {longSting(account ? account : null)}
                  </button>
                ) : (
                  <ConnectWallet classN='nav-btn' />
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;

import React from 'react'
import Header from '../../components/layout/Header'
import Footer from '../../components/layout/Footer'



const Base = (props) => {
  return (
    <div className='d-flex justify-content-between flex-column' >
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export default Base

/** @format */

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "../Layout/Base";
import Home from "../../pages/Home";
import PageNotFound from "../../pages/PageNotFound";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
const RoutesPage = () => {
  function getLibrary(provider) {
    return new Web3(provider);
  }
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
            <Route path='/*' element={<PageNotFound />} />
          </Routes>
        </Base>
      </BrowserRouter>
    </Web3ReactProvider>
  );
};

export default RoutesPage;

/** @format */

import React, { useState, useCallback, useEffect } from "react";
import { GiftedChat, Send, InputToolbar } from "react-web-gifted-chat";
import Header from "../../pages/Layout/Header";
import { MdSend } from "react-icons/md";
import Login from "./Login";
import axios from "axios";
import { Configuration, OpenAIApi } from "openai";
import { GoPrimitiveDot } from "react-icons/go";
import { useWeb3React } from "@web3-react/core";

const Hero = () => {
  const { active } = useWeb3React();

  const [configuration] = useState(
    new Configuration({
      apiKey: "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",
    }),
  );
  const [openai] = useState(new OpenAIApi(configuration));
  const [messages, setMessages] = useState([]);
  const [messages2, setMessages2] = useState([]);

  const [activeChat, setActiveChat] = useState(true);
  const [botName, setBotName] = useState("AI Assistant");

  const [user] = useState(localStorage.getItem("@user"));
  let [data, setData] = useState({});
  let [id, setId] = useState(1);
  useEffect(() => {
    setMessages([
      {
        _id: id,
        text: "Hello i'm AI Square bot",
        createdAt: new Date(),
        user: {
          _id: 2,
          name: "AI Square",
          avatar: "assets/tw.png",
        },
      },
    ]);
    setMessages2([
      {
        _id: id,
        text: `Hello i'm ${botName}`,
        createdAt: new Date(),
        user: {
          _id: 2,
          name: "AI Square",
          avatar: "assets/tw.png",
        },
      },
    ]);
  }, [botName]);

  const newQuery = async msg => {
    // const res = await axios.request(options);
  };

  const onSend = useCallback(async (msg = []) => {
    setMessages(previousMessages => GiftedChat.append(previousMessages, msg));

    let res;
    try {
      res = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: `Human : ${msg[0].text}`,
        max_tokens: 128,
        temperature: 0.9,
        top_p: 1,
        frequency_penalty: 0.0,
        presence_penalty: 0.6,
        stop: [" Human:"],
      });
    } catch (error) {
      console.log(error);
    }
    let result = res.data.choices[0].text;
    // console.log(res.data.choices[0]);
    result = result.replace("\n", "");
    result = result.replace("\n", "");
    result = result.replace(/"?"/g, "");
    result = result.replace(/(.*):/, "");
    id = id + 1;
    const newMessage = {
      _id: id,
      text: result,
      createdAt: new Date(),
      user: {
        _id: 2,
        name: "AI Square",
        avatar: "assets/tw.png",
      },
    };
    setMessages(previousMessages =>
      GiftedChat.append(previousMessages, newMessage),
    );
    await newQuery(msg[0].text);
  }, []);
  function renderInputToolbar(props) {
    return <InputToolbar {...props} containerStyle={styles.toolbar} />;
  }
  const renderSend = props => {
    return (
      <Send {...props} containerStyle={styles.sendContainer}>
        <MdSend size={25} color='#858C94' />
      </Send>
    );
  };

  const onSend2 = useCallback(async (msg = []) => {
    setMessages2(previousMessages => GiftedChat.append(previousMessages, msg));

    const newMessage = {
      _id: id,
      text: `You have to purchase 100 AI2 tokens in order to proceed it further!`,
      // \n \n https://bit.ly/3ToMTZq \n \n
      //  Buy token in presale at 10% discounted price!*`,

      createdAt: new Date(),
      user: {
        _id: 3,
        name: "AI Square bot",
        avatar: "assets/povo-white.png",
      },
    };
    setMessages2(previousMessages =>
      GiftedChat.append(previousMessages, newMessage),
    );
  }, []);

  return (
    <>
      {active ? (
        <>
          <div className='banner'></div>

          <div className='App  container' style={styles.container}>
            {/*  list start */}
            <div
              style={styles.conversationList}
              className='ConversationList rounded-start pt-5'>
              <ul>
                <li
                  className='d-flex align-items-center gap-2'
                  onClick={() => {
                    setActiveChat(true);
                    setBotName("AI Assistant");
                  }}>
                  <img
                    src='assets/tw.png'
                    className='rounded-circle'
                    style={{ width: "50px", height: "50px" }}
                    alt='Avatar'
                  />
                  <div className='div'>
                    <h6 className='mb-0 mt-1'>AI Assistant</h6>
                    <p className='mb-0'>
                      <GoPrimitiveDot className='online-icon' /> online
                    </p>
                  </div>
                </li>
                <li
                  className='d-flex align-items-center gap-2'
                  onClick={() => {
                    setActiveChat(false);
                    setBotName("Website Creator");
                  }}>
                  <img
                    src='assets/tw.png'
                    className='rounded-circle'
                    style={{ width: "50px", height: "50px" }}
                    alt='Avatar'
                  />
                  <div className='div'>
                    <h6 className='mb-0'>Website Creator</h6>
                    {/* <p className='mb-0'>
                      <GoPrimitiveDot className='online-icon' /> online
                    </p> */}
                  </div>
                </li>
                <li
                  className='d-flex align-items-center gap-2'
                  onClick={() => {
                    setActiveChat(false);
                    setBotName("Whitepaper Creator");
                  }}>
                  <img
                    src='assets/tw.png'
                    className='rounded-circle'
                    style={{ width: "50px", height: "50px" }}
                    alt='Avatar'
                  />
                  <div className='div'>
                    <h6 className='mb-0'>Whitepaper Creator</h6>
                    {/* <p className='mb-0'>
                      <GoPrimitiveDot className='online-icon' /> online
                    </p> */}
                  </div>
                </li>
                <li
                  className='d-flex align-items-center gap-2'
                  onClick={() => {
                    setActiveChat(false);
                    setBotName("Twitter Post Creator");
                  }}>
                  <img
                    src='assets/tw.png'
                    className='rounded-circle'
                    style={{ width: "50px", height: "50px" }}
                    alt='Avatar'
                  />
                  <div className='div'>
                    <h6 className='mb-0'>Twitter Post Creator</h6>
                    {/* <p className='mb-0'>
                      <GoPrimitiveDot className='online-icon' /> online
                    </p> */}
                  </div>
                </li>
                <li
                  className='d-flex align-items-center gap-2'
                  onClick={() => {
                    setActiveChat(false);
                    setBotName("Music creator");
                  }}>
                  <img
                    src='assets/tw.png'
                    className='rounded-circle'
                    style={{ width: "50px", height: "50px" }}
                    alt='Avatar'
                  />
                  <div className='div'>
                    <h6 className='mb-0'>Music creator</h6>
                    {/* <p className='mb-0'>
                      <GoPrimitiveDot className='online-icon' /> online
                    </p> */}
                  </div>
                </li>
              </ul>
            </div>

            {/* list end  */}
            {/* 1 */}

            <div className='  chat' style={styles.chat}>
              <Header title={botName} />

              {activeChat ? (
                <GiftedChat
                  user={{ id: 1, avatar: "assets/149071.png" }}
                  isTyping={true}
                  renderSend={renderSend}
                  alwaysShowSend={true}
                  renderInputToolbar={renderInputToolbar}
                  renderUsernameOnMessage={true}
                  messages={messages}
                  onSend={onSend}
                  showUserAvatar={true}
                />
              ) : (
                <GiftedChat
                  user={{ id: 1, avatar: "assets/149071.png" }}
                  isTyping={true}
                  renderSend={renderSend}
                  alwaysShowSend={true}
                  renderInputToolbar={renderInputToolbar}
                  renderUsernameOnMessage={true}
                  messages={messages2}
                  onSend={onSend2}
                  showUserAvatar={true}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='banner'></div>
          <Login />
        </>
      )}
    </>
  );
};

export default Hero;
const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  conversationList: {
    display: "flex",
    flex: 2,
    height: "80vh",
    backgroundColor: "#09101D",
    borderWidth: "1px",
    borderColor: "#394452",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    borderBottomStyle: "solid",
    borderTopStyle: "solid",
    padding: "20px 0px",
    boxShadow:
      "rgb(0 0 2 / 14%) -8px 5px 5px -5px, rgb(35 -2 6 / 32%) 26px 3px 52px 11px, rgb(0 0 0 / 24%) 0px 0px",
  },
  chat: {
    display: "flex",
    flex: 7,
    flexDirection: "column",
    borderWidth: "0px",
    borderColor: "red",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    borderRadius: 3,
  },
  converationDetails: {
    display: "flex",
    flex: 1,
  },
  toolbar: {
    borderRadius: 5,
    borderColor: "#394452",
    borderWidth: 2,
    borderTopColor: "#394452",
    borderTopWidth: 2,
    backgroundColor: "#36363b",
    height: "60px !important",
  },
  sendContainer: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginRight: 15,
    background: "#09101D",
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
